import BaseService from './BaseService'

const ApiService = {
    fetchData(param) {
        
        // Special handling for filterGroups to ensure proper serialization
        if (param.params && param.params.filterGroups) {
            
            // Ensure filterGroups is properly formatted for the API
            // This helps with array serialization in the query string
            const filterGroups = param.params.filterGroups;
            
            // Remove the original filterGroups to avoid double serialization
            const newParams = { ...param.params };
            delete newParams.filterGroups;
            
            // Add each filter group with proper indexing for the API
            filterGroups.forEach((group, index) => {
                newParams[`filterGroups[${index}][field]`] = group.field;
                newParams[`filterGroups[${index}][operator]`] = group.operator;
                newParams[`filterGroups[${index}][value]`] = group.value;
                
                // Only add logical operator if it exists and is not null
                if (group.logicalOperator) {
                    newParams[`filterGroups[${index}][logicalOperator]`] = group.logicalOperator;
                }
            });
            
            // Update the params with the new format
            param.params = newParams;
        }
        
        return new Promise((resolve, reject) => {
            BaseService(param)
                .then((response) => {
                    resolve(response)
                })
                .catch((errors) => {
                    reject(errors)
                })
        })
    },
}

export default ApiService
