'use client';

import React, {useState} from 'react';
import { Button, Dialog } from '@/components/ui'

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}


class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Log the error to an error reporting service
    console.error('Error caught by boundary:', error, errorInfo);

    // Check if the error is a chunk load error
    if (
      error.name === 'ChunkLoadError' ||
      error.message.includes('Loading chunk') ||
      error.message.includes('Loading CSS chunk') ||
      error.message.includes('Failed to fetch dynamically imported module')
    ) {
      // Wait a moment before reloading to ensure the user sees the message
      setTimeout(() => {
        window.location.reload();
      }, 30000);
    }
  }

  private handleRefresh = (): void => {
    window.location.reload();
  };

  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback || (
        <Dialog
            isOpen={true}
        >
            <h5 className="mb-4">System Update</h5>
            <p>
                There has been a system update. Please refresh your page to get the new changes.
            </p>

            <div className="text-right mt-6">
                <Button variant="solid" onClick={this.handleRefresh}>
                    Refresh Page
                </Button>
            </div>
        </Dialog>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary; 